<template>
    <i class="material-icons" v-text="value"></i>
</template>

<script>
export default {
    name: 'phi-icon-google',

    props: {
        value: {
            type: String,
            required: false,
            default: 'help'
        }
    }
}
</script>

<style lang="scss">
// From https://google.github.io/material-design-icons/
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.phi-icon > .material-icons {
    font-size: inherit;
}
</style>